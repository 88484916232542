import Rails from '@rails/ujs';
Rails.start();

import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-mono/400.css';

require('datatables.net-dt');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-fixedheader-dt');
require('datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css');
import $ from 'jquery';
window.$ = jQuery;

require('javascripts/autocomplete.js');
require('javascripts/datatables.js');
require('javascripts/charts.js');
require('javascripts/consent.js');

require('stylesheets/application.scss');
require('stylesheets/datatables.scss');
require('stylesheets/consent.scss');

const images = require.context('../images', true);

// navbar scripts
document.addEventListener('DOMContentLoaded', function(e) {
    const btn = document.querySelector("button.mobile-menu-button");
    const menu = document.querySelector("#menu");
    const btn_svg_on = document.querySelector("button.mobile-menu-button svg.mobile-svg-on");
    const btn_svg_off = document.querySelector("button.mobile-menu-button svg.mobile-svg-off");

    if (btn !== null) {
        btn.addEventListener("click", () => {
            menu.classList.toggle("hidden");
            btn_svg_on.classList.toggle("hidden");
            btn_svg_off.classList.toggle("hidden");
        });
    }

    document.querySelectorAll('nav li.parent').forEach(
        item => item.addEventListener('click', () => {
            item.classList.toggle('hover');
        }));
})
