document.addEventListener('DOMContentLoaded', function (e) {

  // initialize consent_cookie with default values
  let consent_cookie_default = {
    name: 'cookies_accepted_categories',
    value: 'technically_required,analytics,marketing',
    expires: (new Date(Date.now() + 365 * 86400 * 1000)).toGMTString(),
    domain: '.' + window.location.hostname,
    path: '/'
  }
  
  if (document.cookie.indexOf(consent_cookie_default.name + '=') == -1) {
    document.cookie = consent_cookie_default.name + '=' + consent_cookie_default.value
      + '; expires=' + consent_cookie_default.expires
      + '; path=' + consent_cookie_default.path 
      + '; domain=' + consent_cookie_default.domain;
    }

  // Update available Cookie Categories 
  const cookieConsentBannerElement = document.querySelector('cookie-consent-banner');
  cookieConsentBannerElement.availableCategories = [
    {
      description:
        "Enable you to navigate and use the basic functions and to store preferences.",
      key: "technically_required",
      label: "Technically necessary cookies",
      isMandatory: true,
    },
    {
      description:
        "Enable us to determine how visitors interact with our service in order to improve the user experience.",
      key: "analytics",
      label: "Analysis cookies",
    },
    {
      description:
        "Enable us to offer and evaluate relevant content and interest-based advertising.",
      key: "marketing",
      label: "Marketing cookies",
    },
  ];

  $('#show-cookie-banner-link').on('click', function () {
    $('cookie-consent-banner').removeClass('hidden');
    document.dispatchEvent(new Event('cookie_consent_show'));
    return false;
  });

  $('#show-cookie-banner-categories').on('click', function () {
    document.dispatchEvent(new Event('cookie_consent_details_show'));
    return false;
  });
})

// ad_storage	Enables storage (such as cookies) related to advertising.
// ad_user_data	Sets consent for sending user data related to advertising to Google.
// ad_personalization	Sets consent for personalized advertising.
// analytics_storage	Enables storage (such as cookies) related to analytics e.g. visit duration.
// In addition to the consent mode parameters, there are the following privacy parameters:
// Storage Type	Description
// functionality_storage	Enables storage that supports the functionality of the website or app e.g. language settings.
// personalization_storage	Enables storage related to personalization e.g. video recommendations
// security_storage	Enables storage related to security such as authentication functionality, fraud prevention, and other user protection.
// https://developers.google.com/tag-platform/security/guides/consent?hl=pl&consentmode=advanced#tag-manager_3


// COOKIE CONSENT: init user grants after interaction
function updateGrants(event) {
  if (typeof gtag === 'undefined') return;
  const acceptedCategories = event?.detail?.acceptedCategories;

  let consent = {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'denied'
  }

  if (acceptedCategories.includes("analytics")) {
    // console.log("Analytics accepted.");
    consent['analytics_storage'] = 'granted'
  }
  if (acceptedCategories.includes("marketing")) {
    // console.log("Marketing accepted.");
    consent['ad_storage'] = 'granted'
    consent['ad_user_data'] = 'granted'
    consent['ad_personalization'] = 'granted'
  }

  gtag('consent', 'update', {
    'ad_storage': consent['ad_storage'],
    'ad_user_data': consent['ad_user_data'],
    'ad_personalization': consent['ad_personalization'],
    'analytics_storage': consent['analytics_storage']
  });
}

window.addEventListener('cookie_consent_preferences_restored', updateGrants);
window.addEventListener('cookie_consent_preferences_updated', updateGrants);
