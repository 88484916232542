import { Chart, Colors, DoughnutController, ArcElement, LineController, LineElement, PointElement, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js'

Chart.register(
    DoughnutController,
    Colors,
    ArcElement,
    LineController,
    LineElement,
    PointElement,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend
);

$(function () {

    const defaultType = 'bar',
        defaultOptionsScalePosition = 'left',
        defaultDrawOnChartArea = true,
        defaultLineTension = 0,
        defaultBorderWidth = 2,
        defaultDoughnutHoverOffset = 4,
        defaultLegendHoverFontColor = '#000',
        pieDoughnutLegendClickHandler = Chart.overrides.doughnut.plugins.legend.onClick,
        lineColours = {
            0: {
                pointBorderColor: 'rgba(54,162,235,1)',
                pointHoverRadius: 4,
                pointHoverBackgroundColor: 'rgba(200,99,132,1)',
                pointHitRadius: 10
            },
            1: {
                pointBorderColor: 'rgba(200,99,132,1)',
                pointHoverRadius: 4,
                pointHoverBackgroundColor: 'rgba(2,117,216,1)',
                pointHitRadius: 10
            }
        }


    let formats = [], numberFormat,
        currencies = ['USD', 'EUR', 'CNY', 'JPY', 'GBP', 'CNH', 'AUD', 'CAD', 'CHF', 'HKD', 'NZD', 'PLN'];

    function formatValue(value, unit, precision) {
        let formatter;

        unit = unit.replace('/shares', ''); // normalize currency units ex. USD/shares -> USD

        if (currencies.includes(unit)) {
            // switch value precision depending on currency value
            if (Math.abs(value) > 0 && Math.abs(value) < 100.0 || precision == 'precise')
                formatter = formats[unit]['precise']
            else
                formatter = formats[unit]['human'];
        }
        // check other available units ex. '%'
        else if (unit in formats) {
            formatter = formats[unit];
        }
        // default number formatter (for integers)
        else {
            formatter = numberFormat;
        }
        return formatter.format(value);
    }

    function isset(object) {
        return (typeof object !== 'undefined');
    }

    function mychart(canvasId, chartData) {

        if (0 === chartData[0].data.length) // at least one dataset must be filled
            return;

        let canvas = document.querySelector("#" + canvasId),
            config = {
                type: chartData[0].type,
                data: {
                    labels: chartData[0].labels,
                    datasets: []
                },
                options: {
                    maintainAspectRatio: !1,
                    plugins: {
                        legend: {
                            onHover: function (e, legendItem, legend) {
                                e.native.target.style.cursor = "pointer";
                                legendItem.fontColor = defaultLegendHoverFontColor;
                                this.chart.render();
                            },
                            onLeave: function (e, legendItem, legend) {
                                e.native.target.style.cursor = "default";
                                legendItem.fontColor = Chart.defaults.color;
                                this.chart.render();
                            },
                            display: chartData.length > 1 || chartData[0].type == 'doughnut' ? true : false,
                            position: chartData[0].type == 'doughnut' ? 'left' : 'top'
                        },
                        tooltip: {
                            callbacks: {
                                label: function (e) {
                                    let value = isset(e.parsed.y) ? e.parsed.y : e.parsed;
                                    let result = e.dataset.label || "";
                                    let dataset = chartData[e.datasetIndex];
                                    return result && (result += ": "),
                                        null !== value && (result += formatValue(value, dataset.unit, dataset.precision)),
                                        result
                                }
                            }
                        }
                    },
                    scales: {
                    }
                }
            };

        if (chartData[0].type === 'pie' || chartData[0].type === 'doughnut') {
            config.options.plugins.legend.onClick = function (e, legendItem, legend) {
                if (isset(chartData[0].urls) && isset(chartData[0].urls[legendItem.index])) {
                    window.location = chartData[0].urls[legendItem.index];
                }
                else {
                    pieDoughnutLegendClickHandler(e, legendItem, legend);
                }
            }
        };

        config.data.datasets = chartData.map(function (dataset, index, array) {
            let result = {
                label: dataset.dataset_label,
                data: dataset.data,
                backgroundColor: isset(dataset.background_colors) ? dataset.background_colors : null,
                borderWidth: isset(dataset.border_width) ? dataset.border_width : defaultBorderWidth,
                normalized: true,
                type: isset(dataset.type) ? dataset.type : defaultType,
                yAxisID: 'y' + index,
                lineTension: defaultLineTension,
                order: chartData.length - index
            }
            if (result.type == 'line' && isset(lineColours[index])) {
                result = Object.assign(result, lineColours[index]);
            }
            if (result.type == 'doughnut') {
                result.hoverOffset = defaultDoughnutHoverOffset;
            }
            return result;
        });

        // scale settings finish vide https://www.chartjs.org/docs/latest/samples/line/multi-axis.html
        chartData.forEach(function (dataset, index, array) {
            if (dataset.type != 'doughnut') {
                config.options.scales['y' + index] = {
                    beginAtZero: !0,
                    position: isset(dataset.scale_position) ? dataset.scale_position : defaultOptionsScalePosition,
                    ticks: {
                        callback: function (value, t, n) {
                            return ' ' + formatValue(value, dataset.unit, dataset.precision) + '  '
                        }
                    },
                    grid: {
                        drawOnChartArea: isset(dataset.draw_on_chart_area) ? dataset.draw_on_chart_area : defaultDrawOnChartArea, // only want the grid lines for one axis to show up
                    }
                }
            }
        });


        return new Chart(canvas, config);
    }

    Chart.defaults.font.family = "IBM Plex Sans",
        "Intl" in window && Intl.NumberFormat ? (
            numberFormat = Intl.NumberFormat("en", {
                notation: "compact"
            }),
            formats['%'] = [],
            formats['%'] = Intl.NumberFormat("en", {
                style: "unit",
                unit: "percent",
                maximumSignificantDigits: 3
            }),
            currencies.forEach(async (currency) => {
                formats[currency] = [],
                    formats[currency]['human'] = Intl.NumberFormat("en", {
                        style: "currency",
                        currency: currency,
                        notation: "compact"
                    });
                formats[currency]['precise'] = Intl.NumberFormat("en", {
                    style: "currency",
                    currency: currency
                });
            })
        )
            :
            (
                numberFormat = {
                    format: function (e) {
                        return e
                    }
                },
                formats['%'] = [],
                formats['%'] = {
                    format: function (e) {
                        return e
                    }
                },
                currencies.forEach(async (currency) => {
                    formats[currency] = [];
                    formats[currency]['human'] = {
                        format: function (e) {
                            return e
                        }
                    };
                    formats[currency]['precise'] = {
                        format: function (e) {
                            return e
                        }
                    }
                })
            );

    window.mychart = mychart; // makes mychart function global
    "investorChartData" in window && mychart("investor-chart", [investorChartData]);
    "securityChartData" in window && (mychart("security-shares-chart", [securityChartData.shares, securityChartData.price]), mychart("security-value-chart", [securityChartData.value]), mychart("security-investors-chart", [securityChartData.investors]));
    "investorSecurityChartData" in window && (mychart("investor-security-shares-chart", [investorSecurityChartData.shares, investorSecurityChartData.price]), mychart("investor-security-value-chart", [investorSecurityChartData.value]));
    "tagChartData" in window && mychart("tag-value-chart", [tagChartData]);
    "ttmChartData" in window && mychart("ttm-chart", [ttmChartData]);
    "tagChangeChartData" in window && mychart("tag-change-chart", [tagChangeChartData]);
});
